<template>
  <div>
    <noticeManagement v-if="$route.meta.pageTitle=='Profile' && $route.meta.resource=='ACL'"/>
    <b-row
      v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
      class="content-header-left mb-2"
      cols="12"
      md="7"
      >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <!-- START SUDENT LIGIN BREADCRUMB -->
           <h2 class="content-header-title float-left pr-1 mb-0" v-if="$route.meta.pageTitle=='Student Application Mgmt'">
            Application Mgmt
          </h2>
          <!-- <h2 class="content-header-title float-left pr-1 mb-0" v-if="$route.meta.pageTitle=='Student Application Detail' ">
               {{ $route.params.application }}
          </h2> -->
          <!-- END SUDENT LIGIN BREADCRUMB -->
          <h2 class="content-header-title float-left pr-1 mb-0" v-if="$route.meta.pageTitle=='Student Inquiry Information' || $route.meta.pageTitle=='Student Information' || $route.meta.pageTitle=='Employee Information' || $route.meta.pageTitle=='Field & Course Info' || $route.meta.pageTitle=='College Info' || $route.meta.pageTitle=='Application Information' || $route.meta.pageTitle=='Student Application Detail'|| $route.meta.pageTitle=='Manager'|| $route.meta.pageTitle=='Student Edit-Application' || $route.meta.pageTitle =='Edit Inquiry'">
            {{ $route.params.name }}
          </h2>
          <h2 class="content-header-title float-left pr-1 mb-0" v-else>
           <span v-if="$route.meta.pageTitle!=='Student Application Mgmt'"> {{ $route.meta.pageTitle }} </span>
          </h2>
          <div class="breadcrumb-wrapper" v-if="$route.meta.pageTitle!='Student Inquiry Information' && $route.meta.pageTitle!='Student Information' && $route.meta.pageTitle!='Employee Information' && $route.meta.pageTitle!='Field & Course Info' && $route.meta.pageTitle!='College Info' && $route.meta.pageTitle!=='Application Information' && $route.meta.pageTitle!=='Student Application Detail' && $route.meta.pageTitle!=='Manager' && $route.meta.pageTitle!=='Student Edit-Application' && $route.meta.pageTitle!='Edit Application' && $route.meta.pageTitle !=='Edit Inquiry'">
            <b-breadcrumb>
              <b-breadcrumb-item class="breadcrumb_home breadcrumb_home_icon" v-show="$route.meta.resource==undefined" to="/student-inquiry">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item class="breadcrumb_home breadcrumb_home_icon" v-show="$route.meta.resource=='ACL'" to="/student-profile/studentDetail">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item class="breadcrumb_home breadcrumb_home_icon" v-show="$route.meta.resource=='EAC'" to="/employee-profile">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <div class="breadcrumb-wrapper" v-else>
            <b-breadcrumb>
              <b-breadcrumb-item class="breadcrumb_home breadcrumb_home_icon" v-show="$route.meta.resource==undefined" to="/student-inquiry">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
               <b-breadcrumb-item class="breadcrumb_home breadcrumb_home_icon" v-show="$route.meta.resource=='ACL'" to="/student-profile/studentDetail">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item class="breadcrumb_home breadcrumb_home_icon" v-show="$route.meta.resource=='EAC'" to="/employee-profile">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.text=='Application Mgmt' && $route.meta.resource=='EAC'?true:item.active"
                :to="item.to"
              >
              <span v-if="item.text == 'Student Inquiry Information' || item.text == 'Student Information' || item.text == 'Employee Information' || item.text=='Field & Course Info' ||  item.text=='College Info' || item.text=='Application Information'|| item.text=='Manager' || item.text=='Student Edit-Application' || item.text=='edit-application-studentName' || item.text =='Edit Inquiry'">
                {{$route.params.name}}
              </span>
              <span v-else>
                {{item.text}}
              </span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right  mb-1"
      md="5"
      cols="12"
    >
    <!-- DOC MODULE -->
    <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
      <div v-if="$route.meta.pageTitle=='Document Category'" class="top_btn_right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon"
          to="/doc-category-mgmt/create-doc-category"
        > 
          Create New Category
        </b-button>
      </div>
      <!-- DOC MODULE DETAILE -->
      <div v-if="$route.meta.pageTitle=='Education'" class="top_btn_right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon"
          @click.prevent="()=>{$root.$emit('openCategoryEditPage')}"     
        > 
         Edit
        </b-button>
      </div>
      <!-- CATEGORY ADD -->
      <div v-if="$route.meta.pageTitle=='Create New Category'" class="top_btn_right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon"          
          style="margin-right:10px"
          :disabled="isSpinner"
          @click.prevent="()=>{$root.$emit('saveNewCategory');isSpinner=true}"     
        >
         <span v-if="isSpinner">
            <b-spinner small/>
            <span  class="sr-only">Loading...</span>
          </span>          
          <span v-else>Save</span>
        </b-button>
         <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="btn-icon" 
          @click.prevent="()=>{$root.$emit('cancelNewCategory');categoryEditable=false;isSpinner=false}"          
        >
          Cancel
        </b-button>       
      </div>

     <!-- CATEGORY ADD AND UPDATE  -->
      <div v-if="$route.meta.pageTitle=='Edit Category'" class="top_btn_right">  
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon"         
          style="margin-right:10px"
          :disabled="isSpinner"
          @click.prevent="()=>{$root.$emit('updateCategory');isSpinner=true}"     
        >
         <span v-if="isSpinner">
            <b-spinner small/>
            <span  class="sr-only">Loading...</span>
          </span>          
           <span v-else>Save</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="btn-icon" 
          @click.prevent="()=>{$root.$emit('cancelNewCategory');categoryEditable=false;isSpinner=false}"
        >
          Cancel
        </b-button>       
      </div>

      <div v-if="$route.meta.pageTitle=='Edit Doc Module'" class="top_btn_right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon"
          style="margin-right:10px"  
          @click.prevent="()=>{$root.$emit('saveEditedDoc');isSpinner=true}"     
        >
         <span v-if="isSpinner">
            <b-spinner small/>
            <span  class="sr-only">Loading...</span>
          </span>          
           <span v-else>Save</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="btn-icon"      
          @click.prevent="()=>{$root.$emit('cancelEditedDoc');isSpinner=false}"   
        >
          Cancel
        </b-button>
      </div>
    </div>

      <!-- Student Inquiry -->
      <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
        <!-- <div v-if="$route.meta.pageTitle=='Document Category'" class="top_btn_right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            to="/doc-category-mgmt/create-doc-category"
          > 
            Create New Category
          </b-button>
        </div> -->
        <!-- DOC MODULE DETAILE -->
        <!-- <div v-if="$route.meta.pageTitle=='Education'" class="top_btn_right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click.prevent="()=>{$root.$emit('openCategoryEditPage')}"     
          > 
           Edit
          </b-button>
        </div> -->
        <!-- CATEGORY ADD -->
        <!-- <div v-if="$route.meta.pageTitle=='Create New Category'" class="top_btn_right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"          
            style="margin-right:10px"
            :disabled="isSpinner"
            @click.prevent="()=>{$root.$emit('saveNewCategory');isSpinner=true}"     
          >
           <span v-if="isSpinner">
              <b-spinner small/>
              <span  class="sr-only">Loading...</span>
            </span>          
            <span v-else>Save</span>
          </b-button>
           <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon" 
            @click.prevent="()=>{$root.$emit('cancelNewCategory');categoryEditable=false;isSpinner=false}"          
          >
            Cancel
          </b-button>       
        </div> -->
  
       <!-- CATEGORY ADD AND UPDATE  -->
        <!-- <div v-if="$route.meta.pageTitle=='Edit Category'" class="top_btn_right">  
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"         
            style="margin-right:10px"
            :disabled="isSpinner"
            @click.prevent="()=>{$root.$emit('updateCategory');isSpinner=true}"     
          >
           <span v-if="isSpinner">
              <b-spinner small/>
              <span  class="sr-only">Loading...</span>
            </span>          
             <span v-else>Save</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon" 
            @click.prevent="()=>{$root.$emit('cancelNewCategory');categoryEditable=false;isSpinner=false}"
          >
            Cancel
          </b-button>       
        </div>
  
        <div v-if="$route.meta.pageTitle=='Edit Doc Module'" class="top_btn_right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            :disabled="isSpinner"
            @click.prevent="()=>{$root.$emit('closeInquiry');isSpinner=false}"
          >
            Cancel
          </b-button>
        </div> -->
        <div v-if="$route.meta.pageTitle=='Student Inquiry Information'">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
            style="padding: 10px 24.5px !important;box-shadow: none !important;font-size: 17px;font-weight: 600;"
            @click.prevent="()=>{$root.$emit('createAccountInquiry');isSpinner=true}"
          >
            Create Account
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
            style="padding: 10px 24.5px !important;box-shadow: none !important;font-size: 17px;font-weight: 600;"
            :to="{name:'edit-inquiry',params:{id:$route.params.id,name:$route.params.name}}"
          >
            Edit
          </b-button>
        </div>
        <div v-if="$route.meta.pageTitle=='Edit Inquiry'" class="top_btn_right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
            :disabled="isSpinner"
            @click.prevent="()=>{$root.$emit('saveEditInquiry');isSpinner=true}"
          >
            <span v-if="isSpinner">
              <b-spinner small/>
              <span  class="sr-only">Loading...</span>
            </span>
            <span v-else>
              Save
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            :disabled="isSpinner"
            @click.prevent="()=>{$root.$emit('cancelEditInquiry')}"
          >
          Cancel
          </b-button>   
        </div>
      </div>
  
        <!-- Student Inquiry -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Student Inquiry'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon btn__inquiry"
              v-if="!showDeleted"
              @click.prevent="$root.$emit('showDeletedInquiries'),showDeleted=true"
            >
              Show Closed Inquiry
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-else
              class="btn-icon btn__inquiry"
              @click.prevent="$root.$emit('hideDeletedInquiries'),showDeleted=false"
            >
              Hide Closed Inquiry
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon btn__inquiry-mt-3"
              to="/student-inquiry/create-new-inquiry"
            >
              Create New Inquiry
            </b-button>
          </div>
          <div v-if="$route.meta.pageTitle=='Create Inquiry'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="margin-right:10px"
              :disabled="isSpinner"
              @click.prevent="()=>{$root.$emit('saveInquiry');isSpinner=true}"
            >
              <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Save</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click.prevent="()=>{$root.$emit('closeInquiry');isSpinner=false}"
            >
              Cancel
            </b-button>
          </div>
        </div>
  
        <!-- Student mgmt -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Student List'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              to="/student-mgmt/create-new-student"
            >
              Create New Student
            </b-button>
          </div>
          <div v-if="$route.meta.pageTitle=='Student Information' && $route.name != 'edit-student'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon"
              style="margin-right:10px"
              v-show="$route.meta.pageTitle=='Student Information' && userIsActive==true"  
              @click.prevent="()=>{$root.$emit('blockUser');isSpinner=true}"      
            >
              <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Inactive a Student</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon"
              style="margin-right:10px"
              v-show="$route.meta.pageTitle=='Student Information' && userIsActive==false"  
              @click.prevent="()=>{$root.$emit('unBlockUser');isSpinner=true}"
            >
              <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Activate a Student</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              style="margin-right:10px"
              v-show="$route.meta.pageTitle=='Student Information' && $route.meta.resource!='EAC'"
              @click.prevent="()=>{$root.$emit('createApplicationStidentMgmt');isSpinner=false}"
            >
            Create Application 
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="margin-right:10px"
              :to="{name:'edit-student',params:{name:$route.params.name,id:$route.params.id}}"
            >
              Edit
            </b-button>
          </div>
          <div v-if="$route.meta.pageTitle=='Create New Student' || $route.name == 'edit-student'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="margin-right:10px"
              :disabled="isSpinner"
              @click.prevent="()=>{$root.$emit('saveNewStudent');isSpinner=true}"
            >
              <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Save</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click.prevent="()=>{isSpinner=false}"
              :to="{name:'student-information',params:{name:$route.params.name,id:$route.params.id}}"
            >
              Cancel
            </b-button>
          </div>
        </div>
  
        <!-- Application Management -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Application Mgmt'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              to="/application-mgmt/create-new-application"
            >
              Create New Application
            </b-button>
          </div>
          <div v-if="$route.meta.pageTitle=='Create New Application'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="margin-right:10px"
              :disabled="isSpinner"
              @click.prevent="()=>{$root.$emit('createNewApplication');isSpinner=true}"
            >
              <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Save</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              v-if="$route.meta.resource=='EAC'"
              @click.prevent="()=>{isSpinner=false}"
              :to="{name:'student-information',params:{name:$route.params.name,id:$route.params.id}}"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              v-else
              @click.prevent="()=>{isSpinner=false}"
              :to="{name:'application-mgmt'}"
            >
              Cancel
            </b-button>
          </div>
        </div>
  
        <!-- Employee mgmt -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Employees'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              to="/employee-mgmt/create-new-employee"
            >
              Create New Employee
            </b-button>
          </div>
          <div v-if="$route.meta.pageTitle=='Create New Employee' || $route.name == 'edit-employee'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              :disabled="isSpinner"
              style="margin-right:10px"
              @click.prevent="()=>{$root.$emit('saveNewEmployee');isSpinner=true}"
            >
              <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Save</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click.prevent="()=>{$root.$emit('cancelNewEmployee'),isSpinner=false}"
            >
              Cancel
            </b-button>
          </div>
          <div v-if="$route.meta.pageTitle=='Employee Information' && $route.name != 'edit-employee'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon"
              style="margin-right:10px"
              v-if="isEmployeeActive"
              @click.prevent="()=>{$root.$emit('makeEmployeeInactive');isSpinner=true}"
            >
              <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Employee Inactive</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon"
              style="margin-right:10px"
              v-if="!isEmployeeActive"
              @click.prevent="()=>{$root.$emit('makeEmployeeActive');isSpinner=true}"
            >
              <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Employee Active</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              :to="{name:'edit-employee',params:{name:$route.params.name,id:$route.params.id}}"
            >
              Edit
            </b-button>
          </div>
        </div>
  
        <!-- Field & Course Mgmt -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Field & Course Mgmt'" class="top_btn_right">
            <span style="display: flex; justify-content: end;">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              :disabled='isSpinner'
              @click.prevent="()=>{$root.$emit('exportFieldAndCourse');isSpinner=true}"
            >
            <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Export</span>            
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click.prevent="()=>{$root.$emit('importFieldCourse');}"
            >
              Import
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="display: flow-root;"
              to="/uni-corse-mgmt/create-field-course"
            >
              Create New
            </b-button>
            </span>
          </div>
          <div v-if="$route.meta.pageTitle=='Field & Course Info' && $route.name != 'edit-field-course'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              :to="{name:'edit-field-course',params:{name:$route.params.name,id:$route.params.id}}"
            >
              Edit
            </b-button>
          </div>
        </div>
  
        <!-- Create Field & Course -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Create Field & Course' || $route.name == 'edit-field-course'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="margin-right:10px"
              :disabled="isSpinner"
              @click.prevent="()=>{$root.$emit('saveNewFieldCorse');isSpinner=true}"
            >
              <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Save</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click.prevent="()=>{$root.$emit('cancelNewFieldCourse');isSpinner=false}"
            >
              Cancel
            </b-button>
          </div>
        </div>
  
        <!-- University Mgmt -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='University Mgmt'" class="top_btn_right">
            <span style="display: flex; justify-content: end;">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              :disabled='isSpinner'
              @click.prevent="()=>{$root.$emit('exportUniversity');isSpinner=true}"
            >
            <span v-if="isSpinner">
                <b-spinner small/>
                <span  class="sr-only">Loading...</span>
              </span>
              <span v-else>Export</span>            
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click.prevent="()=>{$root.$emit('importUniversity');}"
            >
              Import
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="display: flow-root;"
              @click.prevent="()=>{$root.$emit('createNewUniversity');}"
            >
              Create New University
            </b-button>
            </span>
          </div>
        </div>
  
        <!-- College Mgmt -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='College Mgmt'" class="top_btn_right">
            <span style="display: flex; justify-content: end;">
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click.prevent="()=>{$root.$emit('exportCollege');}"
            >
              Export
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click.prevent="()=>{$root.$emit('importCollegeMgmt');}"
            >
              Import
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="display: flow-root;"
              @click.prevent="()=>{$root.$emit('createNewCollege');}"
            >
              Create New College
            </b-button>
            </span>
          </div>
          <div v-if="$route.meta.pageTitle=='College Info'">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click.prevent="()=>{$root.$emit('open-edit-college')}"
            >
              Edit
            </b-button>
          </div>
        </div>
  
         <!-- Course Mgmt -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Course Mgmt'" class="top_btn_right">
            <span style="display: flex;justify-content: end;">
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click.prevent="()=>{$root.$emit('exportCourse');}"
            >
              Export
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click.prevent="()=>{$root.$emit('importCourse');}"
            >
              Import
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="display: flow-root;"
              @click.prevent="()=>{$root.$emit('createNewCourse');}"
            >
              Create New Course
            </b-button>
            </span>
          </div>
        </div>
  
        <!-- Create New Course Mgmt -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-show="$route.meta.pageTitle=='Create Course'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-1"
              @click.prevent="()=>{$root.$emit('saveCourse');isSpinner=true}"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              class="btn-icon"
              @click.prevent="()=>{$root.$emit('cancelCourse')}"
            >
            Cancel
            </b-button>   
          </div>
        </div>
  
        <!-- Edit Course Mgmt -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-show="$route.meta.pageTitle=='Cyber Security'" class="top_btn_right">       
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click.prevent="()=>{$root.$emit('editCourse');isSpinner=true}"
              :hidden="courceEditable"
            >
            Edit
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-1"
              :hidden="courceEditable==false"
              @click.prevent="()=>{$root.$emit('editCourseDetailSave');isSpinner=true}"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              :hidden="courceEditable==false"
              @click.prevent="()=>{$root.$emit('canceleditCourse')}"
            >
              Cancel
            </b-button>                  
          </div>
        </div>
  
        <!-- ROLE MGMT -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-show="$route.meta.pageTitle=='Employee Roles'" class="top_btn_right">       
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"     
              @click.prevent="()=>{$root.$emit('openCreateRolePopUp');isSpinner=false}"
            >
            Create New Employee Role
            </b-button>                        
          </div>
  
          <div v-show="$route.meta.pageTitle=='Manager'" class="top_btn_right">       
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"  
              @click.prevent="()=>{$root.$emit('userRoleEditOpen');isSpinner=false}"
            >
            Edit
            </b-button>                        
          </div>
        </div>
        
        <!-- DOCUMENT MANAGMENT -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-show="$route.meta.pageTitle=='Document List'" class="top_btn_right">       
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              :hidden="studentProfile"
              @click.prevent="()=>{$root.$emit('createNewDocument');isSpinner=false}"             
            >
            Create Document
            </b-button>                             
          </div>
        </div>
  
        <!-- STUDENT SIDE MODULE -->
        <!-- PROFILE -->
        <div v-show="$route.meta.pageTitle=='Profile' && $route.meta.resource=='ACL'" class="top_btn_right">       
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            :hidden="studentProfile"
            @click.prevent="()=>{$root.$emit('currentStudentProfileEdit')}"             
          >
          Edit
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :hidden="studentProfile==false"
            class="btn-icon  mr-1"     
            @click.prevent="()=>{$root.$emit('currentStudentProfileUpdate');isSpinner=true}"                         
          >
          Save
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            :hidden="studentProfile==false"
            @click.prevent="()=>{$root.$emit('canceleditStudentProfile');isSpinner=false}"            
          >
          Cancel
          </b-button>                        
        </div>
  
        <div v-show="$route.meta.pageTitle=='Student Edit-Application' && $route.meta.resource=='ACL'" class="top_btn_right">       
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"          
            class="btn-icon  mr-1"     
            :disabled="isSpinner"
            @click.prevent="()=>{$root.$emit('studentProfileUpdate');isSpinner=true}"                         
          > 
          <span v-if="isSpinner">
              <b-spinner small/>
              <span  class="sr-only">Loading...</span>
          </span>
          <span v-else>Save</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            @click.prevent="()=>{$root.$emit('studentProfileCancelUpdate')}"            
          >
          Cancel
          </b-button>                        
        </div>
        
        <!-- Employee Module -->
        <div v-show="$route.meta.pageTitle=='Profile' && $route.meta.resource=='EAC'" class="top_btn_right">       
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            :hidden="studentProfile"
            to="/edit-employee-profile"
            v-if="$route.name!='edit-employee-profile'"
          >
          Edit
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            style="margin-right:10px"
            :disabled="isSpinner"
            v-if="$route.name=='edit-employee-profile'"
            @click.prevent="()=>{$root.$emit('saveNewEmployee');isSpinner=true}"
          >
            <span v-if="isSpinner">
              <b-spinner small/>
              <span  class="sr-only">Loading...</span>
            </span>
            <span v-else>Save</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            v-if="$route.name=='edit-employee-profile'"
            @click.prevent="()=>{$root.$emit('cancelNewEmployee');isSpinner=false}"
          >
            Cancel
          </b-button>                     
        </div>
  
        <!-- APPLICATION EDIT MODULE -->
        <div v-show="$route.meta.pageTitle=='Application Information'" class="top_btn_right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
  
            v-if="$route.meta.resource!='EAC'"
  
            @click.prevent="()=>{$router.push({
            name: 'application-mgmt-Edit',
            params:{name:$route.params.name,id:$route.params.id},
            query:{isCourseEdit:false}
            })}"
            :disabled="isSpinner"
          >
            Edit
          </b-button>
        </div>
  
        <!-- APPLICATION EDIT SAVE -->
         <div v-show="$route.meta.pageTitle=='Edit Application'" class="top_btn_right">
             <span>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon" 
                    @click.prevent="()=>{$root.$emit('updateEditApplication');isSpinner=true}"         
                  >
                    <span v-if="isSpinner">
                      <b-spinner small/>
                      <span  class="sr-only">Loading...</span>
                    </span>
                    <span v-else>Save</span>
                  </b-button>
              </span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"     
                  @click.prevent="()=>{$root.$emit('cancelEditApplication');isSpinner=false}"   
                >
                  Cancel
                </b-button>
              </span>
        </div>
  
        <!--PromotionalNotification-->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Promotional Notification'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="promotionalSend"
            >
              Create Promotional Notification
            </b-button>
          </div>
        </div>
        <!--AnnouncmentNotification-->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Announcement'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="announcementCreate"
            >
              Create Announcement
            </b-button>
          </div>
        </div>
        <!--informationdocument-->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Information Document'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="createInfoDoc"
            >
              Create Information Document
            </b-button>
          </div>
        </div>
        <!--Notice Management-->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Notice Management'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="createNotice"
            >
              Create Notice
            </b-button>
          </div>
        </div>
        <!--Application Note Management-->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Application Note Management'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="createApplicationNote"
            >
              Create Application Note
            </b-button>
          </div>
        </div>
        <!--visaApplication-->
        <div v-if="$route.meta.pageTitle=='Visa Documents' && $route.meta.resource=='ACL' && isShowButtonCreateApplication" class="top_btn_right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="createVisaApplication"
          >
            Create Visa Application
          </b-button>
        </div>
        <!--visaApplication-->
        <div v-if="$route.meta.pageTitle=='Visa Application' && $route.meta.resource=='ACL'" class="top_btn_right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            :disabled="isSpinner"
             @click.prevent="()=>{$root.$emit('studentEditVisaApplication'),isSpinner=true}" 
          >
            Save
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            style="margin-left: 10px;"
            class="btn-icon"
            :disabled="isSpinner"
             @click.prevent="()=>{$root.$emit('studentEditCancelVisaApplication'),isSpinner=false}" 
          >
            Cancel
          </b-button>
        </div>
        <!--visaApplication-->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Edit Visa Application'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click.prevent="()=>{$root.$emit('studentEditVisaApplication'),isSpinner=true}"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              style="margin-left: 10px;"
              class="btn-icon"
              :disabled="isSpinner"
              @click.prevent="()=>{$root.$emit('studentEditCancelVisaApplication'),isSpinner=false}" 
            >
              Cancel
            </b-button>
          </div>
        </div>
        <!--visaApplication-->
        <div v-if="$route.meta.pageTitle=='Edit Visa Application' && $route.meta.resource=='ACL'" class="top_btn_right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              :disabled="isSpinner"
              @click.prevent="()=>{$root.$emit('studentEditVisaApplication'),isSpinner=true}"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              style="margin-left: 10px;"
              class="btn-icon"
              :disabled="isSpinner"
              @click.prevent="()=>{$root.$emit('studentEditCancelVisaApplication'),isSpinner=false}" 
            >
              Cancel
            </b-button>
        </div>
        <!-- Field & Course Mgmt -->
        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
          <div v-if="$route.meta.pageTitle=='Degree List Mgmt' || $route.meta.pageTitle=='University List Mgmt' || $route.meta.pageTitle=='School/College List Mgmt' || $route.meta.pageTitle=='Branch/Stream/Field List Mgmt'" class="top_btn_right">
            <span style="display: flex; justify-content: end;">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              style="display: flow-root;"
              @click.prevent="()=>{$root.$emit('saveNewField');}"
            >
              Create New
            </b-button>
            </span>
          </div>
        </div>
        <!--Bank Details Management-->
        <!-- <div v-if="$route.meta.pageTitle=='Bank Details Management'" class="top_btn_right">
          <b-button
            v-if="isShowButtonBankDetails"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="bankDetailForm"
          >
            Add Bank Details
          </b-button>
          <b-button
            v-if="!isShowButtonBankDetails"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="bankDetailForm"
          >
            Edit Bank Details
          </b-button>
        </div> -->
        <!--Invoices Management-->
        <!-- <div v-if="$route.meta.pageTitle=='Invoice Management'" class="top_btn_right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="invoicesCreateFunHandler"
          >
            Create Invoice
          </b-button>
        </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,BSpinner,
} from 'bootstrap-vue'
import noticeManagement from '@/views/apps/student/noticeManagement.vue'
import Ripple from 'vue-ripple-directive'
export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,
    noticeManagement
  },
  created() {
    this.$root.$on('startSpinner',()=>{
      this.isSpinner=true
    })
    this.$root.$on('stopSpinner',()=>{
      this.isSpinner=false
    })
    this.$root.$on('courceEdit',(values)=>{
      this.courceEditable = values
    })
    this.$root.$on('studentProfileEditableOff',(values)=>{
      this.studentProfile = values
    })
    this.$root.$on('editCategory',(event)=>{
      this.categoryEditable=event;
    })
    this.$root.$on('editUserData',(data)=>{
      this.userIsActive = data
      // data.isActive=this.userIsActive;
    })

    this.$root.$on('getEmployeeStatus',(status)=>{
      this.isEmployeeActive=status
    })

    this.$root.$on('showDeletedInquiries',(result)=>{
      this.showDeleted=result;
    })
    this.$root.$on('isShowButtonCreateApplication',(data)=>{
      this.isShowButtonCreateApplication = data
    })
    this.$root.$on('isShowButtonBankDetails',(data)=>{
      this.isShowButtonBankDetails = data
    })
  },
  methods:{
    // editAppRedirect(){      
    //   console.log(this.$route.params.id,"?",this.$route.params.name)
    //   this.$router.push({
    //     name: 'application-mgmt-Edit',
    //     params:{name:this.$route.params.id,id:this.$route.params.name} 
    //   })
    // }
    promotionalSend(){
      this.$router.push({name: 'promotional-notification-send'});
    },
    announcementCreate(){
      this.$router.push({name: 'announcement-create'});
    },
    createInfoDoc(){
      this.$router.push({name: 'information-crerate'});
    },
    createNotice(){
      this.$router.push({name: 'notice-create'});
    },
    createApplicationNote(){
      this.$router.push({name: 'application-note-create'});
    },
    createVisaApplication(){
      this.$router.push({name: 'studentVisaApplication'});
    },
    // bankDetailForm() {
    //   this.$root.$emit('backDetailsForm');
    // },
    invoicesCreateFunHandler() {
      this.$root.$emit('invoicesCreateFunHandler');
    }
  },
  data() {
    return{
      isSpinner:false,
      courceEditable:false,
      studentProfile:false,
      categoryEditable:false,
      userIsActive:true,
      showDeleted:false,
      isEmployeeActive:true,
      isShowButtonCreateApplication:false,
      isShowButtonBankDetails:false,
    }
  },
}
</script>
