
<template>
    <div v-if="currentUserRole == 'student'">
        <div class="tableWrapper" style="margin-bottom: 15px;" v-if="documentList.length > 0 && !isSpinner">
            <div v-for="(data,index) in documentList" :key="index">
                <div class="mainDivWrapper">
                    <div class="d-flex">
                        <div class="iconNoticeAnnounce">
                            <img src="../../../assets/images/erflog/announcement.png" style="filter: invert(1);width: 23px;" v-if="data.isFromNotice == false" class="closeReply cursor-pointer"/>
                            <feather-icon icon="FileTextIcon" style="width: 20px;height: 20px;" color="black" v-if="data.isFromNotice == true" class="closeReply cursor-pointer"/>
                        </div>
                        <div class="titleImageWraapper">
                            <span class="messageTitle">{{ data.title }}</span>
                            <img src="../../../assets/images/erflog/Close_Red.png" class="closeReply cursor-pointer" style="position: absolute;right: 0;" @click="dismiss(data)"/>
                          <span class="messageDiscripetion" :title="data.discription">{{ data.discription }}</span>
                        <div class="btnAttachmtn">
                            <b-button
                                v-if="data.attachment && data.attachmentName" 
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                @click="dowmloadDoc(data)"
                                class="btn-icon"
                            > 
                                Download
                            </b-button>
                        </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "@/utils/firebaseInit.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import Ripple from 'vue-ripple-directive'
import {
 BButton
} from 'bootstrap-vue'
const db = firebase.firestore() 
export default {
    directives: {
        Ripple,
    },
    components: {
        BButton
    },
    data() {
    return {
        isVisible: true,
        message: "This is a notice message.",
        documentList: [],
        documentList1: [],
        documentList2: [],
        currentUserRole: "",
        isSpinner: false
    };
    },
    created(){
        let self = this;
        self.$root.$on("noticePopupOpen", (data) => {
            self.isVisible = data;
        });
        self.isSpinner = true;
        const user = JSON.parse(localStorage.getItem("currentUser"));
        this.currentUserRole = user.role;
        db.collection(dbCollections.NOTICES).where("isDeleted",'==',false)
            .onSnapshot({includeMetadataChanges: true},(snapshot) => {
            if (snapshot.empty) {
                this.isSpinner = false;
                self.documentList2 = [];
                return;
            }
            snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                if(change.type === "added") {
                    if(change.doc.data().status == 1) {
                        this.isVisible = true;
                    }
                    let index = self.documentList2.findIndex((ele)=>{
                        return ele.id == change.doc.data().id
                    })
                    if(index > -1) {
                        let obj = {...change.doc.data()};
                        self.documentList2[index] = obj
                    } else {
                        if(!change.doc.metadata.hasPendingWrites) {
                            let obj = {...change.doc.data()};
                            self.documentList2.push(obj);
                        }
                    }
                }
                if(change.type === "modified") {
                    if(change.doc.data().status = 1) {
                        this.isVisible = true;
                    }
                    let index = self.documentList2.findIndex((x) => x.id === change.doc.id);
                    if(index > -1) {
                        self.documentList2[index] = {...change.doc.data()};
                    } 
                    else {
                        let obj = {...change.doc.data()};
                        self.documentList2.push({...obj});
                    }
                }
                if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                    let index = self.documentList2.findIndex((x) => x.id === change.doc.id);
                    if(index > -1) {
                        self.documentList2.splice(index, 1);
                    }
                }
            })
            self.documentList2.sort((a,b)=>{
                return b.createdAt.seconds - a.createdAt.seconds
            })
            self.$nextTick(()=>{
                self.documentList2 = self.documentList2.filter((ele)=>{
                    return ele.status == 1 && ele.isDeleted == false
                })
                self.documentList2 = self.documentList2.map((ele)=>{
                    return {...ele,'isFromNotice':true}
                })
                self.documentList = self.documentList2.concat(self.documentList1)
                self.$forceUpdate();
                self.isSpinner = false
            })
        })


        db.collection(dbCollections.ANNOUNCEMENT)
            .onSnapshot({includeMetadataChanges: true},(snapshot) => {
            if (snapshot.empty) {
                this.isSpinner = false;
                self.documentList1 = [];
                return;
            }
            snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                if(change.type === "added") {
                    if(change.doc.data().status == 1) {
                        this.isVisible = true;
                    }
                    let index = self.documentList1.findIndex((ele)=>{
                        return ele.id == change.doc.data().id
                    })
                    if(index > -1) {
                        let obj = {...change.doc.data()};
                        self.documentList1[index] = obj
                    } else {
                        if(!change.doc.metadata.hasPendingWrites) {
                            let obj = {...change.doc.data()};
                            self.documentList1.push(obj);
                        }
                    }
                }
                if(change.type === "modified") {
                    if(change.doc.data().status = 1) {
                        this.isVisible = true;
                    }
                    let index = self.documentList1.findIndex((x) => x.id === change.doc.id);
                    if(index > -1) {
                        self.documentList1[index] = {...change.doc.data()};
                    } 
                    else {
                        let obj = {...change.doc.data()};
                        self.documentList1.push({...obj});
                    }
                }
                if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                    let index = self.documentList1.findIndex((x) => x.id === change.doc.id);
                    if(index > -1) {
                        self.documentList1.splice(index, 1);
                    }
                }
            })
            self.documentList1.sort((a,b)=>{
                return b.createdAt.seconds - a.createdAt.seconds
            })
            self.$nextTick(()=>{
                self.documentList1 = self.documentList1.filter((ele)=>{
                    return ele.status == 1
                })
                self.documentList1 = self.documentList1.map((ele)=>{
                    return {...ele,'isFromNotice':false}
                })
                self.documentList = self.documentList2.concat(self.documentList1)
                self.$forceUpdate();
                self.isSpinner = false
            })
        })
    },
    methods: {
        displayFunction(name) {
            let withExte = name.split('.')[0];
            let fName = withExte.split('_')[0];
            if(withExte.split('_').length > 2){
                return withExte.split('_').slice(0,withExte.split('_').length-1).join('_') +'.'+ name.split('.')[1];
            } else {
                return fName +'.'+ name.split('.')[1];
            }
        },
        dismiss(data) {
            let self = this;
            let fIndex = self.documentList.findIndex((ele)=>{
                return ele.id == data.id
            })
            if(fIndex > -1) {
                self.documentList.splice(fIndex, 1);
            }
        },
        dowmloadDoc(fileObject){
            var self = this;
            self.$axios({
                url: fileObject.attachment,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
    
                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileObject.attachmentName);
                document.body.appendChild(fileLink);
    
                fileLink.click();
            })
            .catch(error=>{
                console.error(error)
            })
        },
    },
};
</script>

<style>
.btnAttachmtn{
    text-align: right;
}
.titleImageWraapper{
    display: flex;
    flex-direction: column;
    width: calc(100% - 33px);
    position: relative;
}
.iconNoticeAnnounce {
    width: 33px;
}
.mainDivWrapper{
    padding: 10px 12px;
    border: 1px solid #00aeed;
    display: flex;
    border-radius: 0.428rem;
    flex-direction: column;
    margin-bottom: 2px;
    background-color: #fff;
}
.text-elipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.noDataFound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}
.noDataFoundText {
    font-size: 14px;
    font-weight: 600;
    color: #0d4ea0;
}
.tableWrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #CFCFCF;
    border-radius: 6px;
}
.tableWrapper::-webkit-scrollbar-thumb {
    background: #0d4ea0 !important;
    border-radius: 6px;
}
.tableWrapper{
    overflow: auto;
    max-height: 300px;
    padding: 0 10px 0 0;
}
.tableMain {
    width: 100%;
    border-collapse: collapse;
}
.tableMain tr td {
    padding: 7px;
    border-bottom: 1px solid #ccc;
}
.messageTitle{
    font-size: 15px;
    font-weight: 700;
    width: 40%;
}
.messageDiscripetion{
    font-size: 14px;
    font-weight: 400;
    /* width: 60%; */
}
.cursor-pointer {
    cursor: pointer;
}
</style>