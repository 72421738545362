<template>
    <b-nav-item-dropdown class="dropdown-notification mr-25 notificationBtnBell" menu-class="dropdown-menu-media notificationBtnList" right>
        <template #button-content>
            <feather-icon badge-classes="bg-danger" class="text-body" icon="BellIcon" size="25" @click="getNotificationsData()" />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex" style="align-items: center">
                <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
                <h5 style="padding-right: 10px; color: #2f3990" class="cursor-pointer" @click="markAsAllRead()" v-if="notifications.length > 0">
                    Mark all as read
                </h5>
                <!-- <img src="@/assets/images/erflog/Close_Red.png" class="cursor-pointer" @click.stop="closeMenu()"/> -->
            </div>
        </li>

        <!-- Notifications -->
        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area" tagname="li" v-if="notifications.length > 0">
            <b-link v-for="(notificationObject,index) in notifications" :key="notificationObject.id + index">
                <b-media v-if="notifications.length > 0" @click="readThisNoti(notificationObject)" :class="{ chnageLiColor: !notificationObject.isRead }">
                    <!-- <template #aside>
                        <b-avatar size="32" />
                    </template> -->
                    <div style="display: flex; justify-content: space-between" class="notificationMainDiv">
                        <div>
                            <p class="media-heading">
                                <span class="font-weight-bolder">
                                    {{ notificationObject.title }}
                                </span>
                            </p>
                            <p class="notification-text">{{
                                notificationObject.description
                            }}</p>
                            <span class="notification-time">{{
                                displayDateWithFormat(notificationObject.createdAt)
                            }}</span>
                        </div>
                        <div class="hoverClose">
                            <img src="@/assets/images/erflog/Close_Red.png" class="cursor-pointer" @click.stop="closeNotification(notificationObject)" />
                        </div>
                    </div>
                </b-media>
            </b-link>
            <li style="margin-right: 0px;padding: 10px;" @click="getNotificationsData(true)" v-if="showButtonLoadMore">
                <a href="javascript:void(0)" class="loadmoreclass">Load More</a>
            </li>
        </vue-perfect-scrollbar>
        <li v-else style="text-align: center;padding: 12px;">
            No data found
        </li>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import firebaseApp from "@/utils/firebaseInit";
import firebase from "firebase";
const db = firebaseApp.firestore();
import { dbCollections } from "@/utils/firebaseCollection";
import moment from "moment";

export default {
    components: {
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BAvatar,
        VuePerfectScrollbar,
        BButton,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            notifications: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
            onSnapshotUnsubscribe: null,
            showButtonLoadMore: true,
            lastVisible: null,
            limit: 15,
        };
    },
    methods: {
        displayDateWithFormat(historyObject) {
            return moment(historyObject.seconds * 1000).format('ddd, MMM DD, YYYY [at] hh:mm A')
        },
        readThisNoti(notiObject) {
            try {
                if (notiObject.isRead === false) {
                    let findIndex = this.notifications.findIndex(
                        (x) => x.id === notiObject.id
                    );
                    if (findIndex !== -1) {
                        this.notifications[findIndex].isRead = true;
                    }
                    db.collection(dbCollections.NOTIFICATIONS)
                        .doc(notiObject.id)
                        .update({
                            isRead: true,
                            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                        .catch((error) => {
                            console.error(error, "error");
                        });
                }
            } catch (error) {
                console.error(error, "error");
            }
        },
        markAsAllRead() {
            try {
                let queryRef = db
                    .collection(dbCollections.NOTIFICATIONS)
                    .where("userId", "==", firebaseApp.auth().currentUser.uid);

                queryRef.get().then((querySnapshot) => {
                    querySnapshot.docs
                        .filter((x) => x.data().isRead === false)
                        .forEach((doc) => {
                            db.collection(dbCollections.NOTIFICATIONS)
                                .doc(doc.id)
                                .update({
                                    isRead: true,
                                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                                })
                                .catch((error) => {
                                    console.error(error, "error");
                                });
                        });
                    this.notifications = this.notifications.map((x) => ({
                        ...x,
                        isRead: true,
                    }));
                });

            } catch (error) {
                console.error(error, "error");
            }
        },
        getNotificationsData(loadMore = false) {
            try {
                var self = this;
                let queryRef = db
                    .collection(dbCollections.NOTIFICATIONS)
                    .where("userId", "==", firebaseApp.auth().currentUser.uid)
                    .where("isDelete", "==", false)
                    .orderBy("createdAt", "desc");

                if (loadMore && self.lastVisible) {
                    queryRef = queryRef.startAfter(self.lastVisible);
                }

                self.onSnapshotUnsubscribe = queryRef.limit(self.limit).onSnapshot({ includeMetadataChanges: true },(querySnapshot) => {
                    if (querySnapshot.empty) {
                        self.lastVisible = null;
                        self.showButtonLoadMore = false;
                        return;
                    }

                    if (!loadMore && self.lastVisible === null) {
                        self.lastVisible = querySnapshot.docs[querySnapshot.size - 1];
                    }

                    self.showButtonLoadMore = querySnapshot.docs.length >= self.limit;

                    const modifiedNotifications = [];

                    querySnapshot.docChanges({ includeMetadataChanges: true }).forEach((change) => {

                        const notificationData = change.doc.data();
                        const existingNotificationIndex = self.notifications.findIndex( (obj) => obj.id === notificationData.id);

                        if (change.type === "added") {
                            if (existingNotificationIndex !== -1) {
                                self.notifications[existingNotificationIndex] = {...notificationData};
                            } else {
                                if (loadMore) {
                                    self.lastVisible = change.doc;
                                }
                                self.notifications.push(notificationData);
                            }
                        }

                        if (change.type === "modified" && existingNotificationIndex !== -1) {
                            self.notifications[existingNotificationIndex] = {...notificationData};
                            modifiedNotifications.push(existingNotificationIndex);
                        }
                    });

                    const sortedNotifications = self.notifications.filter((_, index) => !modifiedNotifications.includes(index));

                    self.notifications = [...sortedNotifications, ...modifiedNotifications.map(index => self.notifications[index])];

                    self.notifications.sort((a, b) => {
                        return (
                            b.createdAt.seconds * 1000 - a.createdAt.seconds * 1000
                        );
                    });
                },
                (error) => {
                    console.error(error, "error");
                }
                );
            } catch (error) {
                console.error(error, "error");
            }
        },
        closeNotification(nObj) {
            try {
                let findIndex = this.notifications.findIndex(
                    (obj) => obj.id === nObj.id
                );
                if (findIndex !== -1) {
                    this.notifications.splice(findIndex, 1);
                    db.collection(dbCollections.NOTIFICATIONS)
                        .doc(nObj.id)
                        .update({
                            isDelete: true,
                        })
                        .catch((error) => {
                            console.error(error, "error");
                        });
                }
            } catch (error) {
                console.error(error, "error");
            }
        },
    },
};
</script>

<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .notification-text {
    margin-bottom: 0;
    color: black;
    line-height: 1.3;
    margin-top: 4px;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .notification-text {
    margin-bottom: 0;
}
.loadmoreclass{
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
    font-size: 15px;
    color: #0d4ea0;
    font-weight: 500;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list .media {
    padding: 0.9rem 1.28rem;
    border: none;
    border-bottom: 1px solid #e5e5e5;
}
button.btn.mt-1.mb-0.w-25.btn-primary.btn-block {
    width: 30% !important;
}

.notificationMainDiv .hoverClose {
    opacity: 0;
}

.notificationMainDiv:hover .hoverClose {
    opacity: 1;
}

.notificationClasss {
    display: none;
}

.chnageLiColor {
    background-color: #f0f0f0;
}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media {
    padding: 0.9rem 1.28rem;
    border: none;
    border-bottom: 1px solid #ebe9f1;
}
.notification-time{
    font-size: 12px;
    color: #b5b5b5;
    font-weight: 400;
    margin-top: 5px;
}
.header-navbar .navbar-container ul.navbar-nav li .media-list {
    max-height: 50rem;
}
</style>
