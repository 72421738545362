let data = [];
let currentLoggedIn = {};
currentLoggedIn = JSON.parse(localStorage.getItem('userData'))
if(currentLoggedIn !== null){
  if(currentLoggedIn.role == 'student'){  
    // let childArry =  [
    //   {
    //     title: 'Suggested Universities',
    //     route: 'studentSuggesteduniversity',
    //     icon: 'CircleIcon',
    //     // acl: {
    //     action: 'read',
    //     resource: 'ACL',
    //     // },
    //   },
    //   {
    //     title: 'ShortList Universities',
    //     route: 'studentShortuniversity',
    //     icon: 'CircleIcon',
    //     // acl: {
    //     action: 'read',
    //     resource: 'ACL',
    //     // },
    //   },
    // ]
    // console.log(childArry,"childArry")
    data = [
      {
        header: 'Others',
      },
      {
        title: 'Profile',
        route: 'studentDetail',
        icon: 'UserIcon',
        // acl: {
        action: 'read',
        resource: 'ACL',
        // },
      },
      {
        title: 'Documents',
        route: 'studentDocuments',
        icon: 'FileTextIcon',      
        // acl: {
        action: 'read',
        resource: 'ACL',
        // },
      },
      {
        title: 'Visa Documents',
        route: 'studentVisaDocuments',
        icon: 'FileTextIcon',      
        // acl: {
        action: 'read',
        resource: 'ACL',
        // },
      },
      {
        title: 'Applicaton Mgmt',
        icon: 'ShieldIcon',
        route: 'studentapplicationMgmt',
        // tag: 'new',
        action: 'read',
        resource: 'ACL'
      },
      {
        title: 'SOP',
        icon: 'FileTextIcon',
        route: 'studentsopupload',
        // tag: 'new',
        action: 'read',
        resource: 'ACL'
      },      
      {
        title: 'Information Document',
        icon: 'FileTextIcon',
        route: 'studentinformationDocList',
        // tag: 'new',
        action: 'read',
        resource: 'ACL'
      },      
    ]
  }else{
    data = [];
  }
}
export default data
