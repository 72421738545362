/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import chartsAndMaps from './charts-and-maps'
// import formAndTable from './forms-and-table'
// import studentPage from './studentPage'
import others from './others'
import uiElements from './ui-elements'
// import getRoutes from './employeePages'
import empRoutes from './employeePages'

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
// var empRoutes = []
// getRoutes(res=>{
//     empRoutes = res
// })
// console.log("Final EmpRoutes",empRoutes)

export default [...uiElements,...others,...empRoutes]
// export default [...uiElements,...others]

// }else{
// export default [...dashboard]
// }
