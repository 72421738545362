let data = [];
let currentLoggedIn = {};
currentLoggedIn = JSON.parse(localStorage.getItem('userData'))
if(currentLoggedIn !== null){
  if(currentLoggedIn.role == 'admin')
  {
    data = [
    // {
    //   header: 'Project Tabs',
    // },
    {
      title: 'Student Inquiry',
      route: 'student-inquiry',
      icon: 'MessageSquareIcon',
    },
    {
      title: 'Student List',
      route: 'student-mgmt',
      icon: 'UserIcon',
    },
    {
      title: 'Application Mgmt',
      route: 'application-mgmt',
      icon: 'FileTextIcon',
    },
    {
      title: 'Admin User',
      icon: 'UserIcon',
      // tag: 'new',
      tagVariant: 'light-success',
      children: [
        {
          title: 'Employees',
          route: 'employee-mgmt',
        },
        {
          title: 'Employee Roles',
          route: 'role-mgmt',
        },
      ],
    },
    {
      title: 'University/Courses',
      icon: 'ShieldIcon',
      // tag: 'new',
      tagVariant: 'light-success',
      children: [
        {
          title: 'Field & Course Mgmt',
          route: 'field-course-mgmt',
        },
        {
          title: 'University Mgmt',
          route: 'university-mgmt',
        },
        {
          title: 'College Mgmt',
          route: 'college-mgmt',
        },
      ],
    },
    // {
    //   title: 'Documents',
    //   route: 'doc-category-mgmt',
    //   icon: 'TypeIcon',
    // },
    {
      title: 'Documents',
      icon: 'CopyIcon',
      // tag: 'new',
      tagVariant: 'light-success',
      children: [
        {
          title: 'Document Category',
          route: 'doc-category-mgmt',
        }, 
        {
          title: 'Document List',
          route: 'doc-mgmt',
        }
      ],
    },
    // {
    //   title: 'Document List',
    //   route: 'doc-mgmt',
    //   icon: 'TypeIcon',
    // }  
    {
      title: 'Company Document',
      route: 'company-document',
      icon: 'FileTextIcon',
    },
    {
      title: 'Promotional Notification',
      route: 'promotional-notification',
      icon: 'BellIcon',
    },
    {
      title: 'Announcement',
      route: 'announcement',
      icon: 'SpeakerIcon',
    },
    {
      title: 'Community',
      route: 'community',
      icon: 'UsersIcon',
    },
    {
      title: 'Information Document',
      route: 'information-document',
      icon: 'FileTextIcon',
    },
    {
      title: 'Notice Management',
      route: 'notice-mgnt',
      icon: 'BellIcon',
    },
    {
      title: 'Education Fields Management',
      icon: 'ShieldIcon',
      // tag: 'new',
      tagVariant: 'light-success',
      children: [
        {
          title: 'Degree List Mgmt',
          route: 'degree-list-mgmt',
        },
        {
          title: 'University List Mgmt',
          route: 'university-list-mgmt',
        },
        {
          title: 'School/College List Mgmt',
          route: 'school-college-list-mgmt',
        },
        {
          title: 'Branch/Stream/Field List Mgmt',
          route: 'branch-stream-field-mgmt',
        },
      ],
    },
    // {
    //   title: 'Invoice Management',
    //   route: 'invoice-mgnt',
    //   icon: 'FileTextIcon',
    // },
    // {
    //   title: 'Bank Details Management',
    //   route: 'bank-details-mgnt',
    //   icon: 'SettingsIcon',
    // },
    {
      title: 'Application Note Management',
      route: 'application-note-mgnt',
      icon: 'FileTextIcon',
    },

    //############################################
    // {
    //   header: 'User Interface',
    // },
    // {
    //   title: 'Cards',
    //   icon: 'CreditCardIcon',
    //   tag: 'new',
    //   tagVariant: 'light-success',
    //   children: [
    //     {
    //       title: 'Basic',
    //       route: 'card-basic',
    //     },
    //     {
    //       title: 'Advance',
    //       route: 'card-advance',
    //     },
    //     {
    //       title: 'Statistics',
    //       route: 'card-statistic',
    //     },
    //     {
    //       title: 'Analytics',
    //       route: 'card-analytic',
    //     },
    //     {
    //       title: 'Card Actions',
    //       route: 'card-action',
    //     },
    //   ],
    // },
    // {
    //   title: 'Components',
    //   icon: 'ArchiveIcon',
    //   children: [
    //     {
    //       title: 'Alert',
    //       route: 'components-alert',
    //     },
    //     {
    //       title: 'Aspect',
    //       route: 'components-aspect',
    //     },
    //     {
    //       title: 'Avatar',
    //       route: 'components-avatar',
    //     },
    //     {
    //       title: 'Badge',
    //       route: 'components-badge',
    //     },
    //     {
    //       title: 'Breadcrumb',
    //       route: 'components-breadcrumb',
    //     },
    //     {
    //       title: 'Button',
    //       route: 'components-button',
    //     },
    //     {
    //       title: 'Button Group',
    //       route: 'components-button-group',
    //     },
    //     {
    //       title: 'Button Toolbar',
    //       route: 'components-button-toolbar',
    //     },
    //     {
    //       title: 'Calendar',
    //       route: 'components-calendar',
    //     },
    //     {
    //       title: 'Carousel',
    //       route: 'components-carousel',
    //     },
    //     {
    //       title: 'Collapse',
    //       route: 'components-collapse',
    //     },
    //     {
    //       title: 'Dropdown',
    //       route: 'components-dropdown',
    //     },
    //     {
    //       title: 'Embed',
    //       route: 'components-embed',
    //     },
    //     {
    //       title: 'Image',
    //       route: 'components-image',
    //     },
    //     {
    //       title: 'List Group',
    //       route: 'components-list-group',
    //     },
    //     {
    //       title: 'Media Objects',
    //       route: 'components-media',
    //     },
    //     {
    //       title: 'Modal',
    //       route: 'components-modal',
    //     },
    //     {
    //       title: 'Nav',
    //       route: 'components-nav',
    //     },
    //     {
    //       title: 'Overlay',
    //       route: 'components-overlay',
    //     },
    //     {
    //       title: 'Pagination',
    //       route: 'components-pagination',
    //     },
    //     {
    //       title: 'Pagination Nav',
    //       route: 'components-pagination-nav',
    //     },
    //     {
    //       title: 'Pill',
    //       route: 'components-pill',
    //     },
    //     {
    //       title: 'Pill Badge',
    //       route: 'components-pill-badge',
    //     },
    //     {
    //       title: 'Popover',
    //       route: 'components-popover',
    //     },
    //     {
    //       title: 'Progress',
    //       route: 'components-progress',
    //     },
    //     {
    //       title: 'Sidebar',
    //       route: 'components-sidebar',
    //     },
    //     {
    //       title: 'spinner',
    //       route: 'components-spinner',
    //     },
    //     {
    //       title: 'Tab',
    //       route: 'components-tab',
    //     },
    //     {
    //       title: 'Time',
    //       route: 'components-time',
    //     },
    //     {
    //       title: 'Timeline',
    //       route: 'components-timeline',
    //     },
    //     {
    //       title: 'Toasts',
    //       route: 'components-toasts',
    //     },
    //     {
    //       title: 'Tooltip',
    //       route: 'components-tooltip',
    //     },
    //   ],
    // },
    // {
    //   title: 'Extensions',
    //   icon: 'PlusCircleIcon',
    //   children: [
    //     {
    //       title: 'Sweet Alert',
    //       route: 'extensions-sweet-alert',
    //     },
    //     {
    //       title: 'Toastification',
    //       route: 'extensions-toastification',
    //     },
    //     {
    //       title: 'Slider',
    //       route: 'extensions-slider',
    //     },
    //     {
    //       title: 'Drag & Drop',
    //       route: 'extensions-drag-and-drop',
    //     },

    //     {
    //       title: 'Tour',
    //       route: 'extensions-tour',
    //     },

    //     {
    //       title: 'Clipboard',
    //       route: 'extensions-clipboard',
    //     },
    //     {
    //       title: 'Context Menu',
    //       route: 'extensions-context-menu',
    //     },

    //     {
    //       title: 'Swiper',
    //       route: 'extensions-swiper',
    //     },

    //     // {
    //     //   title: 'Tree',
    //     //   route: 'extensions-tree',
    //     // },

    //     {
    //       title: 'I18n',
    //       route: 'extensions-i18n',
    //     },
    //   ],
    // },
    // {
    //   title: 'Page Layouts',
    //   icon: 'LayoutIcon',
    //   children: [
    //     {
    //       title: 'Collapsed Menu',
    //       route: 'page-layout-collapsed-menu',
    //     },
    //     {
    //       title: 'Layout Boxed',
    //       route: 'page-layout-boxed-layout',
    //     },
    //     {
    //       title: 'Without Menu',
    //       route: 'page-layout-without-menu',
    //     },
    //     {
    //       title: 'Layout Empty',
    //       route: 'page-layout-layout-empty',
    //     },
    //     {
    //       title: 'Layout Blank',
    //       route: 'page-layout-layout-blank',
    //     },
    //   ],
    // },
    ]
  }
  else
  {
    data=[]
  }
}
export default data;