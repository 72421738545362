import firebase from '@/utils/firebaseInit'
const db = firebase.firestore()

var routes = [
]

let data = [];
let currentLoggedIn = {};
currentLoggedIn = JSON.parse(localStorage.getItem('userData'))
if(currentLoggedIn !== null){
    if(currentLoggedIn.role == 'employee')
    {
        data = [
            {
                title: 'Profile',
                route: 'employee-profile',
                icon: 'MessageSquareIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
                title: 'Student Inquiry',
                route: 'student-inquiry',
                icon: 'MessageSquareIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
                title: 'Student List',
                route: 'student-mgmt',
                icon: 'UserIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
                title: 'Application Mgmt',
                route: 'application-mgmt',
                icon: 'FileTextIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
              title: 'Admin User',
              icon: 'UserIcon',
              // tag: 'new',
              tagVariant: 'light-success',
              children: [
                {
                    title: 'Employees',
                    route: 'employee-mgmt',
                    action: 'read',
                    resource: 'EAC',
                },
                {
                    title: 'Employee Roles',
                    route: 'role-mgmt',
                    action: 'read',
                    resource: 'EAC',
                },
              ],
            },
            {
                title: 'University/Courses',
                icon: 'ShieldIcon',
                // tag: 'new',
                tagVariant: 'light-success',
                children: [
                {
                    title: 'Field & Course Mgmt',
                    route: 'field-course-mgmt',
                    action: 'read',
                    resource: 'EAC',
                },
                {
                    title: 'University Mgmt',
                    route: 'university-mgmt',
                    action: 'read',
                    resource: 'EAC',
                },
                {
                    title: 'College Mgmt',
                    route: 'college-mgmt',
                    action: 'read',
                    resource: 'EAC',
                },
                ],
            },
            {
                title: 'Documents',
                icon: 'CopyIcon',
                // tag: 'new',
                tagVariant: 'light-success',
                children: [
                {
                    title: 'Document Category',
                    route: 'doc-category-mgmt',
                    action: 'read',
                    resource: 'EAC',
                }, 
                {
                    title: 'Document List',
                    route: 'doc-mgmt',
                    action: 'read',
                    resource: 'EAC',
                }
                ],
            },
            {
                title: 'Company Document',
                route: 'company-document',
                icon: 'FileTextIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
                title: 'Promotional Notification',
                route: 'promotional-notification',
                icon: 'BellIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
                title: 'Announcement',
                route: 'announcement',
                icon: 'SpeakerIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
                title: 'Community',
                route: 'community',
                icon: 'UsersIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
                title: 'Information Document',
                route: 'information-document',
                icon: 'FileTextIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
                title: 'Notice Management',
                route: 'notice-mgnt',
                icon: 'BellIcon',
                action: 'read',
                resource: 'EAC',
            },
            {
                title: 'Education Fields Management',
                icon: 'ShieldIcon',
                // tag: 'new',
                tagVariant: 'light-success',
                children: [
                  {
                    title: 'Degree List Mgmt',
                    route: 'degree-list-mgmt',
                    action: 'read',
                    resource: 'EAC',
                  },
                  {
                    title: 'University List Mgmt',
                    route: 'university-list-mgmt',
                    action: 'read',
                    resource: 'EAC',
                  },
                  {
                    title: 'School/College List Mgmt',
                    route: 'school-college-list-mgmt',
                    action: 'read',
                    resource: 'EAC',
                  },
                  {
                    title: 'Branch/Stream/Field List Mgmt',
                    route: 'branch-stream-field-mgmt',
                    action: 'read',
                    resource: 'EAC',
                  },
                ],
              },
              {
                title: 'Application Note Management',
                route: 'application-note-mgnt',
                icon: 'FileTextIcon',
                action: 'read',
                resource: 'EAC',
              },
        ]
    }
    else
    {
        data = [];
    }
}

export const getRoutes = () =>
{
    if(currentLoggedIn.role == 'employee')
    {
        // console.log("User Id",JSON.parse(localStorage.getItem('userData')).id)
        // db
        //     .collection('users')
        //     .doc(JSON.parse(localStorage.getItem('userData')).id)
        //     .get()
        //     .then((doc)=>{
        //     console.log("Role ID",doc.data().employeeRole.id)
        //     db
        //         .collection('userRoles')
        //         .doc(doc.data().employeeRole.id)
        //         .get().then(doc2=>{
                    routes.push({
                        title: 'Profile',
                        route: 'employee-profile',
                        icon: 'MessageSquareIcon',
                        action: 'read',
                        resource: 'EAC',
                    })
                    data.forEach(list=>{
                        JSON.parse(localStorage.getItem('userAccess')).forEach(routeData=>{
                            if(list.title == routeData.name)
                            {
                                routes.push(list)
                            }
                        })
                    })
                    // console.log("EmpRoutes",routes)
                    return routes;
        //         })
        //         .catch(error => console.log(error))
        // })
        // .catch(error => console.log(error))
    }
    else
    {
        return routes;
    }
}
// getRoutes(res=>{
//     routes = res
// })
// console.log("Routes",routes)

// export default routes;

export default getRoutes();
// test(() => {
// })
// export default getRoutes() = test();